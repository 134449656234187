import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop } from 'vue-property-decorator';
import { LoraDevice } from '../../shared/models/dto/devices';
import { NameValue } from '../../shared/models/dto/general';
import { DoubleMeasurement, Measurement, StringMeasurement } from '../../shared/models/dto/measurement';
import { Sensor, ITSSensor } from '../../shared/models/dto/sensor';

@Component({
    components: {      
        deviceForm: require('@/views/_components/device/device-form.vue').default,
        errorMessage: require('@/views/_components/error-message/error-message.vue').default,
        sensorChart: require('@/views/_components/device/sensor-chart.vue').default,
        sensorDialog: require('@/views/devices/sensor-dialog.vue').default,
        sensorFormulaDialog: require('@/views/devices/sensor-formula-dialog.vue').default   
    }
})
export default class DeviceDetailsComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly id: number;
    @Prop({ default: '' }) readonly protocolName: string;

    loading = false;
   
    errors: NameValue[] = [];
    sensors: TypedSensor[] = [];
    sensorFormulas: ITSSensor[] = [];
    seriesList: any[] = [];
    show = false;

    sensorsLoading = false;

    showFormulaDialog = false;
    showSensorDialog = false;
    loadingApexChart = false;

    measurement: Measurement[] = [];

    device: LoraDevice = {
        id: null,
        name: null,
        description: null,
        vendorDeviceId: null,
        vendorName: null,
        appKey: null,
        communicationProtocolVersionId: null,
        communicationProtocolVersionDisplayName: null,
        companyId: null,
        deviceEUI: null,
        uplinkIntervalInSeconds: null,
        communicationProtocolVersion: null,
        sensors: null,
        protocolDisplayName: null,
        protocolName: null,
        vendorDeviceName: null,
        vendorId: null,
        isDeleted: false,
    };

    sensorId: number = null;
    sensorName = '';
    sensorFormulaId?: number = null;

    getFormTitle() {
        return this.t('DeviceDetails');
    }

    async mounted() {    
        this.loading = true;
        this.loadingApexChart = true;
        this.sensorsLoading = true;

        if (this.id) {
            this.getDevice().then(async(response) => {
                this.device = response;
                this.loading = false;
                
                await this.loadData(true); 
            });                
        }
    }

    async loadData(initial: boolean, completed?: boolean) {
        await this.authService.getList(`/api/devices/${this.id}/itssensors`).then(async(sensorFormulas: ITSSensor[]) => {
            this.sensorFormulas = sensorFormulas;
            if (initial || (!initial && completed === true)) {
                await this.authService.get<Sensor[]>(
                    `/api/devices/${this.id}/measurements/10/false`, false).then(async(response) => {
                    const sensors = response.content["data"];
                    this.sensors = sensors.map(x => {
                        return {
                            isDoubleMeasurements: !this.isStringMeasurements(x.measurements),
                            ...x
                        };
                    });
                });
            }
            this.sensorsLoading = false;
        });       
    }

    getFormula(sensorId: number) {
        const sensorFormula = this.sensorFormulas.find(x => x.sensorId === sensorId);
        return sensorFormula ? sensorFormula.formula : '';
    }

    getDevice(): Promise<LoraDevice> {
        let request = `/api/${this.protocolName}devices/${this.id}`;
        if (this.AppConsts.auth.isGranted('Permissions_Sensor_Read')) {
            request = request + '?$expand=sensors';
        }

        return new Promise((resolve) => {
            this.authService.get<LoraDevice>(
                request).then((response) => {
                resolve(response.content);
                return response.content;
            });
        });
    }

    isStringMeasurements(measurements: DoubleMeasurement[] | StringMeasurement[]): measurements is StringMeasurement[] {
        return (<StringMeasurement[]>measurements).every(p => p.stringValue !== undefined);
    }

    showSensorFormula(sensorId: number, sensorName: string) {
        this.sensorId = sensorId;
        this.sensorName = sensorName;
        const sensorFormula = this.sensorFormulas.find(x => x.sensorId === sensorId);
        this.sensorFormulaId = sensorFormula ? sensorFormula.iTSDeviceId : null;
        this.showFormulaDialog = true;
    }

    showSensorDetails(sensorId: number, sensorName: string) {
        this.sensorId = sensorId;
        this.sensorName = sensorName;

        this.$nextTick(() => {
            this.showSensorDialog = true;
        });
    }

    showApexChart() {
        this.loadingApexChart = false;
    }

    async closeSensorFormulaDialog(completed: boolean) {
        this.showFormulaDialog = false;

        await this.loadData(false, completed);

        this.showFormulaDialog = false;        
    }

    closeSensorDialog() {
        this.showSensorDialog = false;
    }
}

interface TypedSensor extends Sensor {
    isDoubleMeasurements: boolean;
}