import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { LoraDevice } from '../../../shared/models/dto/devices';
import { VendorDevice } from '../../../shared/models/dto/vendor'
import { CommunicationProtocol, CommunicationProtocolVersion } from '../../../shared/models/dto/communication-protocol';
import { SupportedVendor } from '../../../shared/models/dto/vendor';
import { NameValue } from '../../../shared/models/dto/general';
import buildQuery, { Expand } from 'odata-query';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})

export default class DeviceFormComponent extends AuthComponentBase {
    @Prop({
        default: {
            id: null,
            name: null,
            description: null,
            vendorDeviceId: null,
            vendorName: null,
            appKey: null,
            communicationProtocolVersionId: null,
            companyId: null,
            deviceEUI: null,
            uplinkIntervalInSeconds: null,
            communicationProtocolVersion: null,
            sensors: null,
            protocolDisplayName: null,
            protocolName: null,
            vendorDeviceName: null,
            vendorId: null
        }
    }) readonly device: LoraDevice;

    @Prop({ default: false, required: false }) readonly isEdit: boolean;
    @Prop({ default: false, required: false }) readonly isLoadingDevice: boolean;
    @Prop({ default: false, required: true }) readonly show: boolean;
    @Prop({ default: '', required: true  }) readonly protocolName: string;
    @Prop({ default: '', required: true  }) readonly mode: string;
    @Prop({ default: '', required: false  }) readonly formTitle: string;
    @Prop({ default: null, required: false }) readonly errors: NameValue[];

    loadingCommunicationProtocols = false;
    loadingVendorNames = false;
    loadingVendorDevices = false;
    loadingCommunicationProtocolVersions = false;

    communicationProtocols: CommunicationProtocol[] = [];
    supportedVendors: SupportedVendor[] = [];
    supportedVendorsDisabled: Boolean = true;
    communicationProtocolVersions: CommunicationProtocolVersion[] = [];
    vendorDevices: VendorDevice[] = [];
    vendorDevicesDisabled: Boolean = true;
    communicationProtocolVersionDisabled: Boolean = true;

    valid = false;


    @Watch('show', { immediate: true })
    async onShowChanged() {
        if (this.show && !this.isLoadingDevice) {
            if (this.$refs.form) {
                (this.$refs.form as any).reset();
            }
            this.vendorDevicesDisabled = true;
            this.communicationProtocolVersionDisabled = true; 
            
            if (this.mode !== 'details') {         
                const requests = [];

                this.loadingCommunicationProtocols = true;
                this.loadingVendorNames = true;

                requests.push(this.authService.getList('/api/communicationprotocols'));
                requests.push(this.authService.getList('/api/devices/supportedVendors'));

                Promise.all(requests).then(async(response) => {
                    this.communicationProtocols = response[0];
                    this.loadingCommunicationProtocols = false;
                    this.supportedVendors = response[1];
                    this.loadingVendorNames = false;
                    if (this.isEdit) {
                        if (this.device.vendorId) {
                            await this.onCommunicationProtocolChange();
                            await this.onSuppliedVendorChange();
                            await this.onVendorDeviceChange();
                        }
                    } 
                });
            }
        }
    }

    async onCommunicationProtocolChange() {
        this.loadingVendorNames = true; 
        this.supportedVendorsDisabled = true;

        
        await this.authService.get<SupportedVendor[]>(`/api/supportedvendors?$filter=VendorDevice/any(vd: vd/ProtocolName eq '${this.device.protocolName}' and vd/devicetypeid eq 2)`)
            .then((response) => {                             
                this.supportedVendors = response.content;
                this.loadingVendorNames = false;
                this.supportedVendorsDisabled = false;
            });        
    }

    async onSuppliedVendorChange() {
        this.loadingVendorDevices = true;
        this.vendorDevices = await this.authService.getList(`/api/supportedvendors/${this.device.vendorId}/vendordevices?$filter=devicetypeid eq 2`);
        this.vendorDevicesDisabled = false;
        this.loadingVendorDevices = false;
    }

    async onVendorDeviceChange() {
        this.loadingCommunicationProtocolVersions = true;
        this.communicationProtocolVersions = await this.authService.getList(`/api/vendordevices/${this.device.vendorDeviceId}/protocolversions`);
        this.communicationProtocolVersionDisabled = false;
        this.loadingCommunicationProtocolVersions = false;
    }

    save() {
        this.$emit('save');
    }
}