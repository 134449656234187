import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Measurement } from '@/shared/models/dto/measurement'
import { ODataFilterObject, ODataFilterItem } from '@/shared/models/shared/odata-filter-object';
import { FilterOperator } from '@/shared/models/shared/filter-operator';
import { NameValue } from '../../shared/models/dto/general';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';

@Component({
    components: {
        dateRangeSelection: require('@/views/_components/date-range-selection/date-range-selection.vue').default,
        errorMessage: require('@/views/_components/error-message/error-message.vue').default,
        sensorChart: require('@/views/_components/device/sensor-chart.vue').default,
    }
})
export default class SensorDialogComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly sensorId: number;
    @Prop({ default: null }) readonly sensorName: string;
    @Prop({ default: null }) readonly deviceId: number;
    @Prop({ default: false }) readonly show: boolean;
    @Prop({ default: false }) readonly isComplex: boolean;

    startDate = '';
    endDate = '';
    errors: NameValue[] = [];
    measurements: Measurement[] = [];
    totalCount = 0;
    showDateRangeSelection = false;

    loading = false;

    @Watch('show', { immediate: true })
    async onShowChanged(newValue, oldValue) {
        if (newValue !== oldValue && newValue === true) {
            this.showDateRangeSelection = false;
            this.totalCount = 0;
            this.measurements = [];
            this.errors = [];

            this.showDateRangeSelection = true;
        }
    }

    close() {
        this.showDateRangeSelection = false;
        this.$emit('close');
    }

    async onDateChange(startDate: string, endDate: string) {
        this.errors = [];
        this.startDate = startDate;
        this.endDate = endDate;
     
        if ((this.startDate && this.endDate) && (new Date(this.startDate) < new Date(this.endDate))) {
            this.loading = true;
            await this.getItems();
            this.loading = false;
        } else {
            this.errors = [{
                name: "InvalidDateRange",
                value: this.t('InvalidDateRange')
            }];
        }
                
    }

    async getItems() {
        const filterItems: ODataFilterItem[] = [];

        filterItems.push({ columnName: 'timeAdded', value: new Date(this.startDate), operator: FilterOperator.gt });
        filterItems.push({ columnName: 'timeAdded', value: new Date(this.endDate), operator: FilterOperator.lt });            

        const queryObj: ODataFilterObject = {
            filter: filterItems && filterItems.length ? filterItems : [],
            $count: true
        };

        const query = this.queryBuilder(queryObj);

        await this.authService
            .get<IRestResponseDto<Measurement[]>>(
                `/api/Measurements?deviceId=${this.deviceId}&sensorId=${this.sensorId}&${query.slice(1, query.length)}`)
            .then((response) => {
                this.measurements = <Measurement[]><unknown>response.content;
                this.totalCount = response.count;
            });
    }
}